/**
 * Training Center Telehealth page Template
 */
/* Import section */
import { graphql } from "gatsby"
import React from "react"
import Bannerwithcta from "../components/bannerwithcta"
import Cardguide from "../components/cardguide"
import Cardvideo from "../components/cardvideo"
import Herotype2 from "../components/herotype2"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Titledecorative from "../components/titledecorative"
import "../styles/pages/training.scss"


/* Declaration function */
const Trainingcentertelehealthpage = ({ data }) => {

  //Const data
  const dataPage = data.wpPage
  const dataAcf = dataPage.acfPageTrainingtelehealth

  //Const data Options
  const dataOptionsbanner = data.options.acfPageOptions
  var arrOptionsbanner = []
  arrOptionsbanner = dataOptionsbanner.repeaterBannerCta.filter(function(element){
    return element.topicBannerCta === "support1"
  })

  return (
    <Layout>
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
      />

      {/* Hero section */}
      <section className="section-hero-faqs top-section">
        <Herotype2 h1title={dataAcf.titleH1TrainingTelehealth} textIntro={dataAcf.textHeroTrainingTelehealth} colorBar="white" />
      </section>

       {/*Training guides details section */}
       <section className="section-details-training-guides">
        <div className="wrap-maincontent">
          <Titledecorative title={dataAcf.titleSectionGuidesTelehealth} />
          <div className="wrap-loop-guides grid-loop-card-clickable">
            {dataAcf.repeaterGuidesTelehealth.map( (guide, index) =>
              <Cardguide key={index} titleGuide={guide.titleGuideTelehealth} srcImgGuide={guide.pictureGuideTelehealth.localFile.childImageSharp.gatsbyImageData} slugFileGuide={guide.titleFileGuideTelehealth} />
            )}
          </div>
        </div>
      </section>


      {/*Training videos details section */}
      <section className="section-details-training-videos">
        <div className="wrap-maincontent">
          <Titledecorative title={dataAcf.titleSectionVideosTelehealth} />
          {dataAcf.repeaterModulesVideosTelehealth.map( (module, index1) => (
            <div className="wrap-module">
              <div key={index1} className="title-module-video">
                <h3>{module.titleModulesVideosTelehealth}</h3>
              </div>
              <div className="wrap-loop-videos grid-loop-card-clickable">
                {module.repeaterVideosTelehealth.map( (video, index2) =>
                  <Cardvideo key={index2} index={`${index1}-${index2}`} titleVideo={video.titleVideoTelehealth} leadVideo={video.resumeVideoTelehealth} slugVideo={video.linkVideoTelehealth} />
                )}
              </div>
            </div>
          ))}
        </div>
      </section>


      {/* Banner CTA Training */}
      <Bannerwithcta
        classBanner="section-banner-training"
        titleBanner={arrOptionsbanner[0].titleBannerCta}
        textBanner={arrOptionsbanner[0].textBannerCta}
        ctaLabelBanner={arrOptionsbanner[0].ctaLabelBanner}
        ctaLinkBanner={arrOptionsbanner[0].ctaLinkBanner}
      />
    </Layout>
  )
}

/* Export template informations */
export default Trainingcentertelehealthpage

//QUERY GRAPHSQL ON POST
export const query = graphql`
    query {
      wpPage(slug: {eq: "training-center-telehealth"}) {
        slug
        acfPageTrainingtelehealth {
          repeaterGuidesTelehealth {
            pictureGuideTelehealth {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 800, aspectRatio: 1.5)
                }
              }
            }
            titleFileGuideTelehealth
            titleGuideTelehealth
          }
          repeaterModulesVideosTelehealth {
            repeaterVideosTelehealth {
              linkVideoTelehealth
              resumeVideoTelehealth
              titleVideoTelehealth
            }
            titleModulesVideosTelehealth
          }
          textHeroTrainingTelehealth
          titleH1TrainingTelehealth
          titleSectionGuidesTelehealth
          titleSectionVideosTelehealth
        }
        seo {
          title
          metaDesc
        }
      }
      options: wpPage(slug: {eq: "options-website"}) {
        acfPageOptions {
          repeaterBannerCta {
            ctaLabelBanner
            ctaLinkBanner
            textBannerCta
            titleBannerCta
            topicBannerCta
          }
        }
      }
    }
`
